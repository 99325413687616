/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export const inputBarStyle = css`
    background-color: #343541;
    display: flex;
    position: fixed;
    bottom: 0;
    padding: 10px;
    width: 100%;
    border-top: 1px solid darkgray;
    @media (max-width: 374px) {
        padding-bottom: 30px;
    },
    textarea {
        resize: none;
        background-color: #40414f;
        border: none;
        border-radius: 5px;
        outline: none;
        flex-grow: 1;
        height: 50px;
        line-height: 20px;
        padding: 15px 20px 10px 25px;
        font-size: 16px;
    },
    textarea::placeholder {
        color: darkgray;
    }
`
