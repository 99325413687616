/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export const chatMessagesStyle = css`
    background-color: #343541;
    padding-bottom: 70px;
    height: 100%;
    ,
    .message {
        display: flex;
        gap: 20px;
        padding: 20px;
    },
    .message:nth-of-type(even) pre {
        width: 100%;
        text-align: right;
    },
    .message:nth-of-type(odd){
        background-color: #444654;
        text-align: left;
    },
    .message img {
        width: 40px;    
        height: 40px;    
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
    },
    .message img:hover {
        cursor: pointer;
    },
    .message pre {
        white-space: pre-wrap;
        word-wrap: break-word;
        font-family: Helvetica, sans-serif;
    },
    .warning {
        width: 100%;
        background-color: rgb(204,0,0,0.2);
        border-radius: 5px;
        padding: 10px;
        color: white;
    },
    .warningtriangle {
        cursor: pointer;
        font-size: 20px;
    }
`

export const chatMessagesStyleEmpty = css`
    padding-bottom: 70px;
    height: 100%;
    ,
    .emptyrow {
        display: flex;
        align-items: center;
        justify-content: center;
    },
    .emptyrow div { 
        position: relative;
        color: darkgray;
        margin-top: 50px;
    }
`
