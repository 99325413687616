import { css } from '@emotion/react'

export const sideMenuStyle = (showDrawer) => {
    const left = showDrawer ? '0' : '-100%'
    const transition = showDrawer ? 'left 0.25s, opacity 0.001s;' : 'left 0.25s, opacity 0.5s'

    return css`
      left: ${left};
      transition: ${transition};
      background-color: #202123;
      position: fixed;
      top: 0;
      transition: left .25s;
      height: 100%;
      z-index: 1002;
      display: flex;
      flex-direction: column;
      box-shadow: 3px 3px 3px 3px #000000ba;
      width: 320px;
      ,
      #close {
         background-color: #343541;
         border-bottom: 1px solid darkgray;
         padding: 10px 20px;
         line-height: 2rem;
         width: 320px;
         display: flex;
      },
      #closeButton {
         align-self: flex-start;
         color: #ececf1;
      },
      #closebutton:hover {
         cursor: pointer;
         color: white;
      }
      #chatlist {
         overflow: scroll;
         margin-bottom: 65px;
      },
      #bottombar {
         position: absolute;
         bottom: 0;
         width: 320px;
         background-color: yellow;
         border-top: 1px solid darkgray;
         background-color: #343541;
         display: flex;
         flex-direction: row;
         padding: 15px 20px calc(env(Safe-area-inset-bottom, 0px) + 15px) 20px;
         align-items: center;
         @media (max-width: 374px) {
            padding-bottom: 30px;
          }
      },
      Button {        
         margin-left: auto;
      },
      .emptyrow {
         margin: 0;
         position: absolute;
         top: 45%;
         left: 50%;
         transform: translate(-50%, -50%);
      },
      .emptyrow p { 
         color: darkgray;
      },
      .row {
         line-height: 1.5rem;
         color: #ececf1;
         display: flex;     
      },
      .history {
         margin: 15px 15px 0px 10px;
         padding: 15px 15px 5px 15px;
         border-radius: 10px;
         flex-grow: 1;
         },
      .deletebutton {
         margin: 15px 15px 0px 10px;
         padding: 15px;
         border-radius: 10px;
         white-space: nowrap;
      },
      .history:hover {
         background-color: #343541;
         cursor: pointer;
      },
      .deletebutton:hover {
         background-color: rgba(156, 101, 101, 0.61);
         cursor: pointer;
      }
   `
}