/** @jsxImportSource @emotion/react */

import { buttonStyle, redStyle } from './button.css.js'

/**
 * A Button component which is styled using emotion CSS.
 * 
 * @component
 * @param {Object} props The props of the Button component.
 * @param {boolean} props.disabled Specifies whether the button should be disabled.
 * @param {string} props.text The text to be displayed on the button.
 * @param {function} props.onClick The function to be executed when the button is clicked.
 * @param {string} props.color The color of the button. If it's 'red', additional redStyle will be applied.
 *
 * @example
 * return (
 *   <Button disabled={false} text="Click me!" onClick={handleClick} color="red" />
 * )
 */

const Button = ({ disabled, text, onClick, color }) => {

    /**
     * The style of the button. It's set to buttonStyle by default.
     * If the color prop is 'red', redStyle is added to the button.
     */
    let style = buttonStyle

    if (color === 'red') {
        style = [buttonStyle, redStyle]
    }

    return (
        <button onClick={onClick} css={style}  disabled={disabled} >{text}</button>
    )
}

export default Button