import { css } from '@emotion/react'

export const radioButtonStyle = css`
    margin-left: 20px;
    &[type='radio'] {
        accent-color: #007BFF;
    },
    &:checked + label {
        background-color: #007BFF;
        color: #ffffff;
    },
`
export const labelStyle = (disabled) => {return css`
    color: #ececf1;
    margin: 5px;
    padding: 10px 15px;
    margin-right: 5px;
    background-color: #343541;
    border-radius: 5px;
    transition: all 0.3s ease;
    font-family: Arial, sans-serif;
    cursor: pointer;
    width: max-content;
    word-break: keep-all;
    &:hover {
        cursor: ${disabled ? 'not-allowed;' : 'pointer;'}
        color: ${disabled ? '#ececf1;' : '#212529;'}
        background-color: ${disabled ? '#343541;' : '#e2e6ea;'}
    }
`}
