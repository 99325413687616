/**
 * Class representing an OpenAI API service
 * @class
 */
export default class OpenAiApiService {
    /**
     * Create an instance of OpenAiApiService.
     * @param {string} apiKey - The API key.
     */
    constructor(apiKey) {
        this.apiKey = apiKey
        this.baseUrl = 'https://api.openai.com/v1'
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiKey}`
        }
    }

    /**
     * Create a chat completion.
     * @param {string} model - The model to use for the chat completion.
     * @param {Array} messages - An array of messages.
     * @param {Object} [options={}] - The options object.
     * @returns {Promise<Object>} The response object.
     */
    async createChatCompletion(model, messages, options = {}) {
    
        if(messages[0].role !== 'system') {
            const systemPrompt = {'role': 'system', 'content': options.description}
            messages.unshift(systemPrompt)
        }

        let filteredArrayWithoutErrorMessages = messages.filter(item => !item.error)

        const requestBody = {
            model,
            messages: filteredArrayWithoutErrorMessages,
            temperature: options.temperature
        }   
  
        const response = await this.postRequest('/chat/completions', requestBody)
        return response
    }

    /**
     * Send a post request to the OpenAI API.
     * @param {string} path - The path of the API.
     * @param {Object} body - The body of the request.
     * @returns {Promise<Object>} The response object.
     */
    async postRequest(path, body) {
        const url = this.baseUrl + path
        const options = {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(body)
        }
        try {
            const response = await fetch(url, options)
            if (!response.ok) {
                let json = await response.json()
                return {error:{content: json} }
            }

            const data = await response.json()
            return data

        } catch (error) {
            return {error:{content: 'Kunde inte ansluta till chatGPT.'} }
        }
    }
}