import {createContext, useState } from 'react'

/** 
 * Context for managing app wide state.
 */
export const AppContext = createContext()

/**
 * Component to provide application-wide state.
 */
const AppContextProvider = ({children}) => {

    /** 
     * State variable for storing the API key.
     */
    const [apiKey, setApiKey] = useState('')

    /** 
     * State variable for controlling the display of the drawer.
     */
    const [showDrawer, setShowDrawer] = useState(false)

    /** 
     * State variable for storing the currently selected chat.
     */
    const [currentChat, setCurrentChat] = useState(null)

    /**
     * State variable for storing the chat counter.
     */
    const [chatCounter, setChatCounter] = useState(null)

    /**
     * State variable for storing all chats.
     */
    const [chats, setChats] = useState(null)

    /**
     * State variable for storing the settings.
     */
    const [settings, setSettings] = useState(null)

    return <AppContext.Provider
        value={{
            apiKey,
            setApiKey,
            setShowDrawer,
            showDrawer,
            chats,
            setChats,
            currentChat,
            setCurrentChat,
            chatCounter,
            setChatCounter,
            settings,
            setSettings
        }}
    >
        {children}
    </AppContext.Provider>
}

export default AppContextProvider