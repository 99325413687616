import { useEffect, useContext, useState } from 'react'
import Introduction from './components/Introduction/introduction.js'
import ChatMessages from './components/ChatMessages/chatMessages.js'
import SideMenu from './components/SideMenu/sideMenu.js'
import TitleBar from './components/TitleBar/titleBar.js'
import InputBar from './components/InputBar/inputBar.js'
import './App.css'
import { AppContext } from './AppContext.js'

/**
 * Main application component
 *
 * This component uses the context values from `AppContext`,
 * retrieves stored values from localStorage,
 * and sets up the necessary rendering logic based on those.
 *
 * It also sets up watchers for the `chats`, `currentChat`, and `chatCounter`
 * context values to save these in the localStorage when they change.
 *
 */

function App() {
    const { apiKey, setApiKey } = useContext(AppContext)
    const { chats, setChats } = useContext(AppContext)
    const { currentChat, setCurrentChat } = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const { chatCounter, setChatCounter } = useContext(AppContext)

    /**
     * On component mount, retrieve context values from localStorage
     */
    useEffect(() => {
        const storedApiKey = localStorage.getItem('apiKey')
        if (storedApiKey) {
            setApiKey(storedApiKey)
        }

        const storedChats = localStorage.getItem('chats')
        if (storedChats) {
            const parsedChats = JSON.parse(storedChats)
            setChats(parsedChats)
        }

        const storedCurrentChat = localStorage.getItem('currentChat')
        if (storedCurrentChat) {
            setCurrentChat(JSON.parse(storedCurrentChat))
        }

        const storedChatCount = localStorage.getItem('currentChatCount')
        if (storedChatCount) {
            setChatCounter(JSON.parse(storedChatCount))
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Update localStorage when `chats` context value changes
     */
    useEffect(() => {
        if (chats !== null && Object.keys(chats).length >= 0) {
            localStorage.setItem('chats', JSON.stringify(chats))
        }
    }, [chats])

    /**
     * Update localStorage when `currentChat` context value changes
     */
    useEffect(() => {
        if (currentChat !== null && typeof (currentChat) !== 'undefined') {
            localStorage.setItem('currentChat', JSON.stringify(currentChat))
        }
    }, [currentChat])

    /**
     * Update localStorage when `chatCounter` context value changes
     */
    useEffect(() => {
        if (chatCounter !== null && typeof (chatCounter) !== 'undefined') {
            localStorage.setItem('currentChatCount', JSON.stringify(currentChat))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatCounter])

    if (loading) {
        return <div></div>
    }

    return <>
        {apiKey ? (
            <>
                <TitleBar />
                <ChatMessages>
                    <SideMenu />
                </ChatMessages>
                <InputBar />
            </>
        ) : (
            <Introduction></Introduction>
        )}
    </>
}

export default App
