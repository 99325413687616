import { css } from '@emotion/react'

export const modalStyle = (open) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 50px;
    z-index: 1000;
    display: ${open ? 'flex' : 'none'};
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow: scroll;
    flex-direction: column;
    background-color: #343541;
    gap: 10px 0px;
    ,
    Button {
        align-self: center;
    },
    div {
        margin-bottom: 5px;
        text-align: left;
    },
    pre {
        margin-bottom: 5px;
    }
`

export const overlayStyle = (open) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: ${open ? 'block' : 'none'};
`