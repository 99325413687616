/** @jsxImportSource @emotion/react */

import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../AppContext.js'
import RadioButton from './RadioButton/radioButton.js'
import Textfield from './Textfield/textfield.js'
import { settingsStyle } from './settings.css.js'

let defaultSettings = {
    model: 'gpt-3.5-turbo',
    temperature: 1,
    description: 'Du är en svensk version av chatGPT med ett glatt humör.',
}

/**
 * The Settings component for the application.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The  component.
 */
const Settings = (props) => {
    const { currentChat } = useContext(AppContext)
    let chatId = currentChat
    const modelList = [{ 'name': 'gpt-3.5-turbo', 'description': 'Snabb, Billig, Lägre intelligens' }, { 'name': 'gpt-4', 'description': 'Långsam, Dyr, Högre intelligens' }]
    const { setSettings } = useContext(AppContext)


    /**
     * Load the initial settings from local storage or default if no settings are found.
     * @returns {} The loaded settings.
     */
    const loadInitialSettings = () => {
        if (!chatId) return null // Skip if no chatId

        const loadedSettings = JSON.parse(localStorage.getItem('chatSettings')) || {}
        return loadedSettings[chatId] || { ...defaultSettings }
    }

    const initialSettings = loadInitialSettings() || { ...defaultSettings }

    const [model, setModel] = useState(initialSettings?.model)
    const [temperature] = useState(initialSettings?.temperature)
    const [description, setDescription] = useState(initialSettings?.description)

    /** 
     * Handle the model selection changes.
     * @param {Event} e - The event object.
     */
    const handleModelChange = (e) => setModel(e.target.value)
    
    /**
     * Handle the description text changes.
     * @param {Event} e - The event object.
     */
    const handleDescriptionChange = (e) => setDescription(e.target.value)

    // Use an effect hook to update the JSON output and localStorage whenever any state changes
    useEffect(() => {
        if (!chatId) return // Skip if no chatId

        const newSettings = {
            model: model,
            temperature: Number.parseFloat(temperature),
            description: description
        }

        // Update default settings
        defaultSettings = newSettings

        // Load all settings from localStorage
        const allSettings = JSON.parse(localStorage.getItem('chatSettings')) || {}

        // Update this chat's settings and save back to localStorage
        allSettings[chatId] = newSettings
        localStorage.setItem('chatSettings', JSON.stringify(allSettings))

        setSettings(newSettings)
    }, [model, temperature, description, chatId, setSettings])
    return (
        <div css={settingsStyle(props.disabled)}>
            <div>
                {modelList.map((modelName) => (
                    <RadioButton id={modelName} type="radio" name="model" key={modelName.name} value={modelName.name} description={modelName.description} checked={model === modelName.name} onChange={handleModelChange} disabled={props.disabled}>
                        {modelName.name}
                    </RadioButton>
                ))}
            </div>
            <Textfield description={description} handleDescriptionChange={handleDescriptionChange} disabled={props.disabled} />
        </div>
    )
}

export default Settings