/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import { AppContext } from '../../AppContext.js'
import { titleBarStyle } from './titleBar.css.js'

/**
 * This is a title bar component in a chat application. It includes a menu button, a title that changes
 * based on the current chat, and a button to create a new chat.
 * @function TitleBar
 * @returns {JSX.Element} a styled div that acts as the title bar
 */
const TitleBar = () => {
    // Obtain the needed state variables and methods from context
    const { setShowDrawer } = useContext(AppContext)
    const { chats } = useContext(AppContext)
    const { currentChat, setCurrentChat } = useContext(AppContext)
    const { chatCounter, setChatCounter } = useContext(AppContext)


    /**
     * Opens the side navigation drawer.
     * @function openNav
     */
    const openNav = () => {
        setShowDrawer(true)
    }

    /**
     * Creates a new chat and increases the chat counter by one.
     * @function newChat
     */
    const newChat = () => {
        const currentId = chatCounter
        setChatCounter(currentId + 1)
        setCurrentChat(parseInt(currentId) + 1)
    }
    let title = 'Ny Chatt'

    let thread = null
    if (chats !== null && currentChat !== null) {
        // Search for the chat with the currentChat id
        thread = chats.find(chat => chat.id === currentChat)

        if (thread && thread.title !== null && typeof (thread.title) !== 'undefined') {
            title = thread.title
        }
    }

    return (
        <div id="topbar" css={titleBarStyle}>
            <div id="menubutton" onClick={openNav}>Meny</div>
            <div>{title}</div>
            <div id="plussign" onClick={newChat}>+</div>
        </div>
    )
}

export default TitleBar
