import { css } from '@emotion/react'

export const settingsStyle = (disabled) =>  { return css`
    margin:15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: ${disabled ? '#40414f;' : ' #444654;'}
    border-radius: 8px;
    box-shadow: ${disabled ? 'none;' : '0 2px 8px rgba(0, 0, 0, 0.1);'}
    gap: 20px;
    padding: 20px;
    ,
    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    },
    div:nth-of-type(2) {
        width: 100%;
    }
`
}