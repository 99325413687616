/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect, useContext } from 'react'
import { AppContext } from '../../AppContext.js'
import OpenAiApiService from '../../api/openAiApiService.js'
import Button from '../Button/button.js'
import { inputBarStyle } from './inputBar.css.js'

/**
 * InputBar is a React component that renders an input field for chat messages, 
 * sends user messages to the OpenAiApiService, and manages the chat history in 
 * the AppContext.
 * 
 * @returns JSX.Element
 */
const InputBar = () => {
    const [inputValue, setInputValue] = useState('')
    const [requestInProgress, setRequestInProgress] = useState(false)
    const inputRef = useRef(null)
    const { apiKey } = useContext(AppContext)
    const { chats, setChats } = useContext(AppContext)
    const { currentChat } = useContext(AppContext)
    const { settings } = useContext(AppContext)
    const [inputFocus, setInputFocus] = useState(null)

    // Focus on the input field when creating a new chat and after clicking the "Send" button with the mouse
    if (inputRef.current && inputFocus !== currentChat) {
        inputRef.current.focus()
        setInputFocus(currentChat)
    }

    /**
     * Sets the focus to the input element on initial render.
     */
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    /**
     * Handles changes to the input value.
     * 
     * @param {Object} event - The event object from the onChange event.
     */
    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    /**
     * Handles clicking of the send button.
     * This function creates a new message object with the input content, updates 
     * the chat history, sends the message to the OpenAiApiService, and handles 
     * the response.
     * 
     */
    const handleSendClick = async () => {
        const chatId = currentChat // A integer, set to 1 from the start

        if (inputValue.trim() !== '') {
            const userMessage = {
                content: inputValue,
                timestamp: new Date().toISOString(),
                role: 'user',
            }

            let chatToUpdate = null
            // Find the chat with the current ID and push the new message into its messages
            if (chats !== null) {
                chatToUpdate = chats.find(chat => chat.id === chatId)
            } else {

                await setChats([])
            }

            if (!chatToUpdate) {

                // If chatToUpdate is undefined, create a new chat object with the currentChat id
                chatToUpdate = { id: currentChat, title: 'Ny Chatt', messages: [] }
                if (chats === null) {
                    setChats(chatToUpdate)
                } else {
                    chats.push(chatToUpdate)
                }
            }

            chatToUpdate.messages.push(userMessage)

            setInputValue('')
            setInputFocus(null)
            setRequestInProgress(true)
            if (chats !== null) {

                setChats([...chats])
            }

            const apiService = new OpenAiApiService(apiKey)

            let historyWithoutTimestamp = chatToUpdate.messages.map(obj => {
                const { timestamp, data, ...rest } = obj
                return rest
            })

            if (chatToUpdate?.ignore) {
                historyWithoutTimestamp = historyWithoutTimestamp.slice(chatToUpdate.ignore)
            }

            const response = await apiService.createChatCompletion(settings.model, historyWithoutTimestamp, settings)

            let assistantMessage = {}

            if (response.error) {
                assistantMessage = {
                    role: 'assistant',
                    error: response.error.content,
                    timestamp: new Date().toISOString(),
                    data: response.error.content
                }
            } else {
                assistantMessage = {
                    role: 'assistant',
                    content: response.choices[0].message.content,
                    timestamp: new Date().toISOString(),
                    data: response
                }
            }

            // Add the assistant's message to the chat
            chatToUpdate.messages.push(assistantMessage)

            if (response?.error?.content?.error?.code === 'context_length_exceeded') {
                chatToUpdate.ignore = chatToUpdate.messages.length

            }

            // Update the state
            setChats([...chats])
            setRequestInProgress(false)

            if (chatToUpdate.title === null || typeof (chatToUpdate.title) === 'undefined' || chatToUpdate.title === 'Ny Chatt' || chatToUpdate.title === '') {

                let title = chatToUpdate.messages[0].content

                if (title.length > 35) {
                    title = title.substring(0, 32) + '...'
                }

                chatToUpdate.title = title
                setChats([...chats])
            }
        }
    }

    /**
     * Handles keypress events in the text input.
     * If the Enter key is pressed without holding Shift, this function calls 
     * handleSendClick().
     * 
     * @param {Object} event - The event object from the onKeyDown event.
     */
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            if (!requestInProgress) {
                handleSendClick()
            }
        }
    }

    return (
        <div id="inputbar" css={inputBarStyle}>
            <textarea value={inputValue} ref={inputRef} onChange={handleInputChange} onKeyDown={handleKeyPress} placeholder="Skriv ditt meddelande..."></textarea>
            <Button onClick={handleSendClick} disabled={requestInProgress} text='Skicka'></Button>
        </div>
    )
}

export default InputBar
