import { css } from '@emotion/react'

export const buttonStyle = css`
    max-width: 100px;
    box-sizing: border-box;
    padding: 8px 12px;
    background-color: #0077cc;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    &:hover {
        background-color: #005fa3;
    },
    &:active {
        background-color: #004c87;
    },
    &:disabled {
        background-color: #9bb7d4;
        cursor: not-allowed;
    }
`

export const redStyle = css`
    padding: 8px 12px;
    background-color: #a5263b;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    &:hover {
        background-color: #931c30;
    },
    &:active {
        background-color: #a5263b;
    },
    &:disabled {
        background-color: #9bb7d4;
        cursor: not-allowed;
    }
`

