import { css } from '@emotion/react'

export const introductionStyle = css`
padding-top: 20vh;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  text-align: center;
  color: #ececf1;
  background-color: #343541;
  gap: 20px 0px;
  ,
  #info {
    margin-top: auto;
    font-size: 16px;
  },
  #info p {
    max-width: fit-content;
    margin: auto;
    padding-top: 2px;
  },
  #info p:hover {
    color: white;
  },
  #error {
    color: #ff4040;
  }
`

export const apiKeyInputStyle = css`
  margin-top: 20px;
  padding: 10px 15px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #40414f;
  &:focus {
    border-color: #0077cc;
  }
`

export const apiKeyButtonStyle = css`
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #0077cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #005fa3;
  }
  &:active {
    background-color: #004c87;
  }
`
