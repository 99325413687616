/**
 * Checks if the provided API key is valid.
 * A valid API key is a non-empty string with a minimum length of 10 characters.
 *
 * @param {string} apiKey - The API key to validate.
 * @returns {boolean} - Returns true if the API key is valid, otherwise false.
 * @throws {Error} - Throws an error if the API key is not a string or is too short.
 */
export function isValidApiKey(apiKey) {
    if (typeof apiKey !== 'string') {
        throw new Error('Invalid input: API key must be a string.')
    }
  
    const trimmedApiKey = apiKey.trim()
    
    if (trimmedApiKey.length < 10) {
        throw new Error('Invalid input: API key is too short. It must be at least 10 characters long.')
    }
  
    return trimmedApiKey.length > 0
}

/**
 * Censors the API key by replacing characters with asterisks.
 * Censors all characters except the first three and the last three characters.
 *
 * @param {string} apiKey - The API key to censor.
 * @returns {string} - Returns the censored API key.
 */
export function censorApiKey(apiKey) {
    const length = apiKey.length
  
    if (length <= 6) {
        return '*'.repeat(length)
    } else {
        const firstThree = apiKey.slice(0, 3)
        const lastThree = apiKey.slice(-3)
        const censoredMiddle = '*'.repeat(3)
        return firstThree + censoredMiddle + lastThree
    }
}