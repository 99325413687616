/** @jsxImportSource @emotion/react */

import { radioButtonStyle, labelStyle} from './radioButton.css.js'

/**
 * RadioButton is a React component for creating a radio button.
 *
 * @component
 * @param {ReactNode} props.children - Any children components to be rendered within this component.
 * @param {string} props.value - The value associated with this radio button.
 * @param {boolean} props.checked - Indicates whether this radio button is currently checked.
 * @param {Function} props.onChange - The function to be called when the radio button's state is changed.
 * @param {boolean} props.disabled - Indicates whether this radio button is disabled.
 * @param {string} props.description - The description or tooltip text to be shown for this radio button.
 * @returns {ReactElement} The RadioButton react component
 */
const RadioButton = ({ children, value, checked, onChange, disabled, description }) => {

    return (
        <div>
            <div>
                <input 
                    type="radio"
                    id={value}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    css={radioButtonStyle}/>
                <label
                    htmlFor={value}
                    css={labelStyle(disabled)}                     
                    title = {description}>
                    {children}
                </label>
            </div>
        </div>
    )
}

export default RadioButton
