import { css } from '@emotion/react'

export const titleBarStyle = css`
    background: rgba(52, 53, 65, 0.8);
    backdrop-filter: blur(5px);
    border-bottom: 1px solid darkgray;
    padding: 10px 20px;
    line-height: 2rem;
    width: 100%;
    position: sticky;
    color: #ececf1;
    top: 0px;
    display: flex;,
    ,
    div:nth-of-type(2) {
        text-align: center;
        flex-grow: 1;
    },
    #plussign {
        font-size: 1.8rem;
        font-weight: lighter;
        color: #ececf1;
    },
    #plussign:hover {
        color: white;
        cursor: pointer;
    },
    #menubutton:hover {
        color: white;
        cursor: pointer;
    }
`
