/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useRef } from 'react'
import { sideMenuStyle } from './sideMenu.css.js'
import { censorApiKey } from '../../helpers/ApiKeyHelper.js'
import { AppContext } from '../../AppContext.js'
import Button from '../Button/button.js'

/**
 * SideMenu component.
 * @returns {JSX.Element} The SideMenu component.
 */
const SideMenu = () => {

    const {
        showDrawer, setShowDrawer,
        apiKey, setApiKey,
        chats, setChats,
        setCurrentChat,
        chatCounter, setChatCounter,
        setSettings
    } = useContext(AppContext)

    const sideDrawerRef = useRef()

    /**
     * useEffect hook for handling outside click for sideDrawer
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sideDrawerRef.current && !sideDrawerRef.current.contains(event.target)) {
                setShowDrawer(false)
            }
        }

        document.addEventListener('pointerdown', handleClickOutside)
        return () => {
            document.removeEventListener('pointerdown', handleClickOutside)
        }
    }, [setShowDrawer])

    /**
     * Closes the navigation drawer.
     */
    const closeNav = () => {
        setShowDrawer(false)
    }

    /**
     * Resets the application.
     */
    const resetApplication = () => {
        localStorage.removeItem('apiKey')
        localStorage.removeItem('currentChatCount')
        localStorage.removeItem('chats')
        localStorage.removeItem('chatSettings')
        setShowDrawer(false)
        setChats([])
        setCurrentChat(1)
        setChatCounter(1)
        setApiKey('')
        setSettings('')
    }

    /**
     * Opens a chat by id.
     * @param {number} id - The id of the chat to open.
     */
    const openChat = (id) => {
        setCurrentChat(id)
    }

    /**
     * Deletes a chat by id.
     * @param {number} idToDelete - The id of the chat to delete.
     */
    const deleteChat = (idToDelete) => {
        const currentId = chatCounter
        setChatCounter(currentId + 1)
        setCurrentChat(parseInt(currentId) + 1)
        const updatedChats = chats.filter(chat => chat.id !== idToDelete)
        setChats(updatedChats)
    }

    const messages = Array.isArray(chats) && chats.length > 0

    return (
        <aside ref={sideDrawerRef} id="sideNav" css={sideMenuStyle(showDrawer)}>
            <div id="close">
                <div id="closebutton" onClick={closeNav}>
                    Stäng
                </div>
            </div>
            <div id="chatlist">
                {messages ? null : <div className="emptyrow"> <p>Ingen historik</p></div>}
                {chats.map((chat) => (
                    <div key={chat.id} className="row">
                        <div className="history" onClick={() => openChat(chat.id)}>
                            <p>{chat.title}</p>
                        </div>
                        <div className="deletebutton" onClick={() => deleteChat(chat.id)}>
                            Ta bort
                        </div>
                    </div>
                ))}
            </div>
            <div id="bottombar">
                <div>
                    <p>Token: {censorApiKey(apiKey)}</p>
                </div>
                <Button onClick={resetApplication} text='Återställ' color='red'></Button>
            </div>
        </aside>
    )
}

export default SideMenu
