/** @jsxImportSource @emotion/react */
import { useContext, useRef, useEffect, useState } from 'react'
import { AppContext } from '../../AppContext.js'
import Settings from '../Settings/settings.js'
import Modal from '../Modal/modal.js'
import { chatMessagesStyle, chatMessagesStyleEmpty } from './chatMessages.css.js'

/**
 * ChatMessages component handles displaying chat messages
 * 
 * @param {Object} props - The properties object, here used to receive the children props
 * @param {Object} props.children - Children components that will be rendered inside this component
 * @returns {JSX.Element} - A component that represents the chat messages
 */
const ChatMessages = ({ children }) => {

    // The useContext hook is used to access the global context defined in the AppContext
    const { chats, currentChat } = useContext(AppContext)

    // useRef hook is used to access and interact with DOM elements directly within functional components.
    const lastMessageRef = useRef(null)

    // Add state to manage displaying the data object and modal visibility
    const [displayData, setDisplayData] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    /**
     * useEffect hook is triggered after every render where chats is updated
     * Here it is used to scroll to the last chat message
     */
    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [chats])

    let thread = { messages: [] }

    if (chats !== null) {
        thread = chats.find(chat => chat.id === currentChat)
    }

    // If there is no thread, return a JSX element with empty chat instructions and settings
    if (!thread) {
        return (
            <div css={chatMessagesStyleEmpty} >
                {children}
                <Settings></Settings>
                <div className="emptyrow"> <div>Börja chatta!</div></div>
            </div>
        )
    }

    /**
     * Function to handle closing the modal
     * Clears the display data and closes the modal
     */
    const handleCloseModal = () => {
        setIsModalOpen(false)
        setDisplayData(null)
    }

    // If there is a thread, return a JSX element with all the chat messages and settings
    return (
        <div css={chatMessagesStyle}>
            {children}
            <Settings key={currentChat} chatId={currentChat} disabled={true}></Settings>

            {thread.messages.map((message, index) => (
                <div className='message' key={message.timestamp} ref={index === thread.messages.length - 1 ? lastMessageRef : null}>
                    {message.data && message.error ?
                        <div className="warningtriangle" onClick={(e) => { e.stopPropagation(); setDisplayData(message.data); setIsModalOpen(true) }}> ⚠️</div>
                        : null}
                    {message.data && !message.error ?
                        <img src="/icons/icon-144x144.png" alt="AI-avatar" onClick={(e) => { e.stopPropagation(); setDisplayData(message.data); setIsModalOpen(true) }} />
                        : null}

                    <pre className={message.error ? 'warning' : null} >{message.error ? JSON.stringify(message.error, null, 4) : message.content}</pre>
                </div>
            ))}

            {isModalOpen && displayData &&
                <Modal open={isModalOpen} onClose={handleCloseModal} title='Fullständigt svarsobjekt' pre={true}>
                    <pre>{JSON.stringify(displayData, null, 4)}</pre>
                </Modal>
            }
        </div>
    )
}

export default ChatMessages
