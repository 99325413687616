/** @jsxImportSource @emotion/react */

import Button from '../Button/button.js'
import {overlayStyle, modalStyle} from './modal.css.js'

const Modal = ({ open, onClose, title, children, pre }) => {

    return (
        <>
            <div css={overlayStyle(open)} onClick={onClose}></div>
            <div css={modalStyle(open)}>
                <h2>{title}</h2>
                {pre ? <pre>{children}</pre> : <div>{children}</div>}
                <Button onClick={onClose} text='Stäng' color='red'></Button>
            </div>
        </>
    )
}

export default Modal