import { css } from '@emotion/react'

export const textareaStyles = css`
    resize: none;
    text-align: center;
    width: 100%;
    font-size: 16px;
    resize: none;
    background-color: #40414f;
    border: none;
    border-radius: 5px;
    outline: none;
    flex-grow: 1;
    height: 75px;
    line-height: 20px;
    padding: 15px 20px 10px 25px;
    
    &:disabled {
        cursor: not-allowed;
    },
    &:hover {
        color: white;
    },
    &::placeholder {
        color: darkgray;
    }
`