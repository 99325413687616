/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect, useContext } from 'react'
import { introductionStyle, apiKeyInputStyle } from './introduction.css.js'
import { isValidApiKey } from '../../helpers/ApiKeyHelper.js'
import { AppContext } from '../../AppContext.js'
import Modal from '../Modal/modal.js'
import Button from '../Button/button.js'

/**
 * A functional component that provides an introduction to the application, asking the user to input their API key.
 *
 * @returns {JSX.Element} The component.
 */
const Introduction = () => {
    const { setApiKey, setChats, setCurrentChat, setChatCounter } = useContext(AppContext)
    const [tempApiKey, setTempApiKey] = useState('')
    const inputRef = useRef(null)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const [apiKeyError, setApiKeyError] = useState(null)

    /**
     * Handles the opening of a modal with specified content.
     *
     * @param {Object} content The content to be displayed in the modal.
     */
    const handleOpenModal = (content) => {
        setModalContent(content)
        setModalOpen(true)
    }

    /**
     * Handles the closing of the modal.
     */
    const handleCloseModal = () => {
        setModalOpen(false)
    }

    /**
     * Sets the focus to the input element on initial render.
     */
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    /**
     * Handles the changing of the API key.
     *
     * @param {Object} event The event.
     */
    const handleApiKeyChange = (event) => {
        setTempApiKey(event.target.value)
        setApiKeyError(null)
    }

    /**
     * Handles the submission of the API key.
     */
    const handleSubmit = () => {

        if (tempApiKey.trim() === '') {
            // setApiKeyError('API-nyckeln kan inte vara tom.') 
        } else {
            try {
                if (isValidApiKey(tempApiKey)) {
                    setChats([])
                    setCurrentChat(1)
                    setChatCounter(1)
                    localStorage.setItem('apiKey', tempApiKey)

                    setApiKey(tempApiKey)
                }
            } catch (error) {
                setApiKeyError('API-nyckeln måste innehålla minst 10 tecken.')
            }
        }
    }

    /**
     * Handles the key press event. If the key is 'Enter', it triggers the handleSubmit function.
     *
     * @param {Object} event The event.
     */
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
            event.preventDefault()
        }
    }

    // Privacy policy and about us content. Each value in the array is a new paragraph
    const privacypolicy = [
        'Applikationen körs i användarens webbläsare och lagrar API-nyckel, konversationshistorik och inställningar lokalt.', 'Den enda datan som lämnar webbläsaren är användarens chattmeddelanden som skickas direkt till ChatGPT API.',
        'Användaren ansvarar för att förstå användarvillkoren för deras API-nyckel och hur ChatGPT API hanterar personlig data som skickas till deras API.'
    ]

    const aboutus = [
        'Denna applikation utvecklades som en del av kursen 1D026 i Webbprogrammeringsprogrammet vid Linnéuniversitetet.',
        'Applikationen använder OpenAIs ChatGPT API för att erbjuda en chattupplevelse.', 'Applikationen är inte ett officiellt gränssnitt för OpenAI eller ChatGPT och har inget sammarbete med OpenAi.'
    ]

    return (
        <div css={introductionStyle}>
            <div>
                <h1>Välkommen till Chat With Ai!</h1>
                <p>Var god och skriv in din API-nyckel nedan.</p>
            </div>
            <div>
                <input
                    ref={inputRef}
                    css={apiKeyInputStyle}
                    type="password"
                    value={tempApiKey}
                    onChange={handleApiKeyChange}
                    onKeyDown={handleKeyPress}
                    placeholder="API-nyckel"
                />
                <Button onClick={handleSubmit} text='Spara' disabled={!tempApiKey.trim()}></Button>
                {apiKeyError && <p id="error"><br />{apiKeyError}</p>}


            </div>
            <div id="info">
                <p onClick={() => handleOpenModal({ title: 'Om Appen', text: aboutus })} style={{ cursor: 'pointer' }}>
                    Om Appen
                </p>
                <p onClick={() => handleOpenModal({ title: 'Integritetspolicy', text: privacypolicy })} style={{ cursor: 'pointer' }}>
                    Integritetspolicy
                </p>
            </div>
            {modalOpen && modalContent &&
                <Modal open={modalOpen} onClose={handleCloseModal} title={modalContent.title}>
                    {modalContent.text.map((paragraph, index) => (
                        <><p key={index}>{paragraph}</p><br /></>
                    ))}
                </Modal>
            }
        </div>
    )
}

export default Introduction
