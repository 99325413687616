/** @jsxImportSource @emotion/react */

import { textareaStyles } from './textfield.css.js'

/**
 * A Textfield component for user input
 * @component
 * @param {Object} props - Component props
 * @param {string} props.description - The current value of the textarea
 * @param {Function} props.handleDescriptionChange - Handler function that is called when the textarea value changes
 * @param {boolean} props.disabled - If true, the textarea is disabled
 */
const Textfield = ({ description, handleDescriptionChange, disabled }) => {
    return (
        <div>
            <textarea
                css={textareaStyles}
                value={description}
                onChange={handleDescriptionChange}
                disabled={disabled}
                placeholder='Systemmeddelandet hjälper till att ställa in assistentens beteende.'
            />
        </div>
    )
}

export default Textfield
